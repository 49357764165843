<template>
  <div class="designer-integral-pannel">
    <div class="integral-left">
      <div class="div-scroll">
        <ul class="card">
          <li class="card-one">
            <div class="card-one-item">
              <p class="num-wrapper">
                <img
                  src="../../../assets/images/icon/icon_jifen.png"
                  alt=""
                  class="icon-jifen"
                />
                <span class="num">我的积分:{{ userScore }}</span>
              </p>
              <!-- <p class="title">我的积分</p> -->
            </div>
            <div class="split"></div>
            <div class="card-one-item">
              <p class="num-wrapper">
                <img
                  src="../../../assets/images/icon/icon_paimin.png"
                  alt=""
                  class="icon-paimin"
                />
                <span class="num">我的排名:{{ userRank }}</span>
              </p>
              <!-- <p class="title"></p> -->
            </div>
          </li>
          <!-- <li class="card-two">
            <span>邀请好友换积分</span>
          </li> -->
          <!-- <li class="card-three">
            <span>上传作品换积分</span>
          </li> -->
          <!-- <li class="card-four">
            <div class="gonglue">
              <img
                src="../../../assets/images/icon/icon_gonglue.png"
                alt=""
                class="icon-gonglue"
              />
              <span>更多积分攻略>></span>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="integral-right">
      <!--<div class="title">
         <div class="wrapper">
          <img
            src="../../../assets/images/icon/icon_paimin.png"
            alt=""
            class="icon-paimin"
          />
          <span class="text">更多积分排名>></span>
        </div> 
      </div>-->
      <div class="content">
        <div class="content-item content-left">
          <div class="item-title">我的加分</div>
          <div class="item-content">
            <div class="list-wrapper">
              <div class="div-scroll">
                <ul class="list list-add">
                  <li class="list-item" v-for="item in addList" :key="item.id">
                    <span class="text">{{ item.name }}</span>
                    <span class="add-num">{{ item.score }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="pageParams1.total"
                :current-page="pageParams1.currentPage"
                @current-change="handleAddChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div class="content-item content-right">
          <div class="item-title">我的减分</div>
          <div class="item-content">
            <div class="list-wrapper">
              <div class="div-scroll">
                <ul class="list list-add">
                  <li
                    class="list-item"
                    v-for="item in reduceList"
                    :key="item.id"
                  >
                    <span class="text">{{ item.name }}</span>
                    <span class="add-num">{{ item.score }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="pageParams2.total"
                :current-page="pageParams2.currentPage"
                @current-change="handleReduceChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- <div class="buy">
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SUCCESS_CODE: 0,
      userRank: null,
      userScore: 0,
      pageParams1: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        currentPage: 1,
      },
      pageParams2: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        currentPage: 1,
      },
      addList: [], // 加分列表
      reduceList: [], // 减分列表
    };
  },
  created() {
    this.getUserScore();
    this.getUserRank();
    this.getIntrgralList(1, this.pageParams1);
    this.getIntrgralList(-1, this.pageParams2);
  },
  methods: {
    // 获取用户积分数
    getUserScore() {
      this.$http.get("/user-info/load").then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          this.userScore = res.data.score;
        }
      });
    },
    // 获取积分排名
    getUserRank() {
      this.$http.get("/user-info/rank").then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          this.userRank = res.data;
        }
      });
    },
    // 获取积分记录（加分列表，减分列表）
    getIntrgralList(tag, pageParams) {
      let params = Object.assign({}, pageParams, { tag });
      this.$http.get("/score-log/listPage", { params }).then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          if (tag == 1) {
            this.addList = res.data.records;
            this.pageParams1.total = res.data.total;
            this.pageParams1.currentPage = res.data.current;
          } else if (tag == -1) {
            this.reduceList = res.data.records;
            this.pageParams2.total = res.data.total;
            this.pageParams2.currentPage = res.data.current;
          }
        }
      });
    },
    handleAddChange(current) {
      this.pageParams1.pageNum = current;
      this.getIntrgralList(1, this.pageParams1);
    },
    handleReduceChange(current) {
      this.pageParams1.pageNum = current;
      this.getIntrgralList(-1, this.pageParams1);
    },
  },
};
</script>